<script>
import { ref } from "vue";
import { mapActions } from "vuex";
import formAnagraficaCliente from "@/components/widgets/formAnagraficaCliente";
/*
import {
    CountTo
  } from "vue3-count-to";
*/
import userSearchModel from "@/components/widgets/userSearchModel";

import gestioneProdottiPreventivo from "@/components/widgets/worksites/gestioneProdottiPreventivo";

import gcloud from "@/components/gcloud";
import timelineTasksWidget from "@/components/widgets/timelineTasksWidget";


import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
//import appConfig from "../../../../app.config";
import moment from 'moment'
import serverConfig from '@/helpers/config';
import Swal from "sweetalert2";
import axios from 'axios';
import {
    required,
    helpers
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  page: {
    title: "Dettaglio Commessa - Piano della Qualità",
    meta: [{ name: "description", content: serverConfig.APP_NAME }],
  },
  setup() {
    let files = ref([]);
    moment.locale('it')
    let tk = 'Bearer '+localStorage.getItem('tk')
    return { files, tk, v$: useVuelidate() };
  },
  data() {
    return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        showMainQP1:true,
        showMainQP2:false,
        showMainCustomer:false,
        showMainProject:false,
        showMainSystems:false,
        showMainSurvey:false,
        showMainAdmin:false,
        showMainDocuments:false,
        showAmmProgect:false,
        showTechProgect:false,
        showAdvancedProgect:false,
        title: "Piano di Qualità",
        data:{
          source:'',
          customerCategory:'',
          systemCategory:'',
          systemSubCategory:'',
          fiscalBenefit:'',
          surveyDetails:{
              date:moment.unix(),
              dateStr:moment().format('YYYY-MM-DD'), 
              status: '', 
              response:'',
              note:'',
              createdBy:{}, 
          },
          accountingDetails:{
              date:moment.unix(),
              dateStr:moment().format('YYYY-MM-DD'), 
              status: '', 
              response:'',
              note:'',
              createdBy:{}, 
          },            
        },
        optionsFiscalBenefit:[],
        optionsFSPrivate:[
          { value: '', label: 'Seleziona beneficio fiscale' },
          { value: 'conto_termico', label: 'Conto termico' },
          { value: 'detrazione_fiscale', label: 'Detrazione fiscale' },
        ],
        optionsFSBusiness:[
          { value: '', label: 'Seleziona beneficio fiscale' },
          { value: 'credito_imposta', label: "Credito d'imposta" },
          { value: 'sconto_in_fattura', label: 'Sconto in fattura' },
        ],
        optionsFSPA:[
          { value: 'non_previsto', label: 'Non previsto' },
        ],
        optionsSystems:[],
        optionsSystemsACS:[
          { value: '', label: 'Seleziona sottocategoria ACS' },
          { value: 'scalda_acqua_in_pdc', label: 'Scalda acqua in pompa di calore' },
          { value: 'solare_termico', label: 'Solare Termico' },
        ],
        optionsSystemsClima:[
          { value: '', label: 'Seleziona sottocategoria Climatizzazione' },
          { value: 'area_area', label: "Area area" },
          { value: 'area_acqua', label: 'Area acqua' },
          { value: 'rap', label: 'R.A.P' },
          { value: 'termo_idraulica', label: 'Termo idraulica' },
          { value: 'vrv', label: 'VRV' },
        ],
        optionsSystemsED:[
          { value: '', label: 'Seleziona sottocategoria Edilizia' },
          { value: 'coibentazione_mc', label: "Coibentazione Manti Ceramic" },
          { value: 'coibentazione_eps', label: 'Coibentazione EPS' },
          { value: 'impiantistica_idrica', label: 'Impiantistica idrica' },
          { value: 'ristrutturazioni', label: 'Ristrutturazioni' },
        ],
        optionsSystemsEL:[
          { value: '', label: 'Seleziona sottocategoria Energia elettrica' },
          { value: 'FTV', label: "Fotovoltaico" },
          { value: 'sistemi_di_accumulo', label: 'Sistemi di accumulo' },
          { value: 'colonnine_di_rc', label: 'Colonnine di ricarica' },
          { value: 'impiantistica_elettrica', label: 'Impiantistica elettrica' },
        ],
        famList:[], 
        checkCustomerID:false,
        checkSystem:false,
        n_required:0,
        n_true:0,
        r:0,
      project:{
        tipo:'commessa', // Tipologia di lavoro => 'Commessa', prevalentement
        code:'code',
        fonteAcquisizione:'',
        tipologiaCommessa:'', // privato/azienda
        beneficioFiscale:'',
        sopralluogo:'',
        dettagliSopralluogo:{},
        registrazioneAmministrativa:'no',
        dettagliRegistrazioneAmministrativa:{},
        modPagamento:'',
        subappalto:'no',
        dettagliSubappalto: 'no',
        ftv:'no',
        dettagliFtv:{},
        suap:'no',
        autorizzazioneSuap:'no',
        praticaEnel:'no',
        dettagliPraticaEnel:{},
        cantiere:'no',
        cantiereID: '',   
        dettagliCantiere:{},
        progettazione: 'no',
        dettagliProgettazione:{},
        parent: '',
        name: '',
        descriptions: '',
        status:'init',
        start:0,
        end:0,
        start_tmp:0,
        end_tmp:0,
        year:0,
        month:0,
        day:0,
        user:{},
      },
      customerDetails:{        
        CodStato: 'IT',
        code:'',
        ragSoc:'',
        partitaIva:'',
        codiceFiscale:'',
        codeDest:'',
        email:'',
        pec:'',
        telefono:'',
        CodiceAlternativo_1:'',
        CodiceAlternativo_2:'',
        indirizzo:'',
        citta:'',
        provincia:'',
        cap:'',
        Stato:'',
        TipoSoggetto:'',
        date_joined:'',
      },
      qualityArr:                  {
        n:1,
        mod: '',
        rev: 0,
        titolo: '',
        statoLavori: '',
        codiceCUP: '',
        codiceCIG: '',
        importoLavori:0.00,
        importoOneriSicurezza:0.00,
        dataContratto: '',
        rep: '',
        vistoRGQ: '',
        dataDocumento: '',
        oggetto:'',	
        note: '',
        year: new Date().getFullYear(),
      },
      opzioneBeneficio:[],
      worksite:{
            projectID: false,
            area:"",
            status:"init", // 'init'/'inprogress'/'pending'/'completed'/'problem'
            name:"",
            description:"",
            type:"",
            country:"",
            zipCode:"",
            city:"",
            address:"",
            address_number:"",
            additional_info_to_address:"",
            cordinate_system:"",
            longitude:"",
            latitude:"",
            year: new Date().getFullYear(),
            dateString:"",
            dateTmp:0,
        
            tasks:[],
            createdBy:this.$store.state.auth.currentUser,
            versions_history:{},
            assignedTo:[],
            startStr: "", 
            endStr: "",
            start: moment.unix(),
            end: "",
            start_tmp: moment.unix(),
            end_tmp: "",
            color: "",
            icon: "",
            url: "",
            geolocal:"",
            
            statusClass: "info",
            priority: "low",
            priorityClass: "success",
      },
      tasksList:[],
      items: [
        {
          text: "Elenco commesse per Piano di Qualità",
          href: "/quality-control/list-view",
        },
        {
          text: "Dettaglio Piano di Qualità",
          active: true,
        },
      ],

      content: "",


    };
  },
    validations: {
      data: {
        source: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        customerCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemSubCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        fiscalBenefit: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
      },
    },
  mounted() {
    this.getItem()
    this.items = [
        {
          text: "Elenco commesse per Piano di Qualità",
          href: "/quality-control/list-view",
        },
        {
          text: "Dettaglio Piano di Qualità",
          active: true,
        },
        ];
  },
  beforeMount(){
  
    this.axiosInterceptor()
    axios.get(`${serverConfig.EP}users/acl`, 
        { 
          headers: { 'authorization':this.tk},
          params: { 
            page: this.$router.currentRoute._rawValue.name,
          },
        },

      ).then(response => {
        console.log('acl')
        console.log(response.data.user.roles_permissions.pages)
          if (response.data){
            if (response.data.user){
              if (response.data.user.roles_permissions.layout) {
                this.changeLayoutType({layoutType:response.data.user.roles_permissions.layout})
              }
            }
          }
      });
  },
  methods: {
    ...mapActions('layout', ['changeLayoutType', ]),
    axiosInterceptor(){
      axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:3500,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                  Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:3000,
                    })
                    this.$router.push('/')            
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
    },
    convertTmpToDate(val){
        return moment.unix(val).format('LLL')
    },
    filterOptions(val){
        switch(val){
          case "azienda":
            this.optionsFiscalBenefit = this.optionsFSBusiness
            break;
          case "privato":
            this.optionsFiscalBenefit = this.optionsFSPrivate
            break;
          case "pubblico":
            this.optionsFiscalBenefit = this.optionsFSPA
            break;
        }   
        console.log( this.optionsFiscalBenefit )     
    },    
    filterOptionsSystem(val){
        switch(val){
          case "acqua_calda_sanitaria":
            this.optionsSystems = this.optionsSystemsACS
            break;
          case "climatizzazione":
            this.optionsSystems = this.optionsSystemsClima
            break;
          case "edilizia":
            this.optionsSystems = this.optionsSystemsED
            break;
          case "energia_elettrica":
            this.optionsSystems = this.optionsSystemsEL
            break;
        }
      console.log(this.optionsSystems)
    },    
    deleteRecord(ele) {
      ele.target.parentElement.parentElement.remove();
    },
    cambiaOpzioneBeneficio(){
        let privato = [
          {"value":"sconto-fattura","text":"Sconto in fattura"},
          {"value":"conto-termico","text":"Conto Termico"},
          {"value":"nessuno","text":"Nessuno"},
        ]
        let azienda = [
          {"value":"credito-imposta","text":"Credito d'imposta"},
          {"value":"sconto-fattura","text":"Sconto in fattura"},
          {"value":"nessuno","text":"Nessuno"},
        ]    
       
      if (this.project.tipologiaCommessa =='privato'){
        this.opzioneBeneficio = privato
      } else {
        this.opzioneBeneficio = azienda
      }   
    },
    showMainQP(){
     
      document.getElementById("genDataForm").submit();
    },
    getParent(){
      this.getItem()
    },
    checkDocuments(array){
      if (array){
        let count = array.length
        let checkTrue = array.filter(function(el){
          return el.exist == true
        })
        
        if (count > checkTrue.length ){
          this.r = count - checkTrue.length
        } 
        this.n_required = count
        this.n_true = checkTrue

        return true
      } else {
        return false
      }

    },
    getItem() {
      this.data = {}
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}projects/${this.$route.params.id}`, {headers: { authorization:this.tk}}).then((response) => {
          if (response.data) {
              this.data = response.data[0]
              if (!this.data.accountingDetails) {
                this.data.accountingDetails.contract = false
              }
              
              if(!this.data.customerDetails){
                this.data.customerDetails = this.customerDetails
              }
              if(!this.data.quality) {
                this.data.quality = [
                  this.qualityArr
                ]
              } else {
                if (this.data.quality.length==0) {
                  this.data.quality = [
                    this.qualityArr
                  ]
                } else {
                  if(!this.data.quality[0].note){
                    this.data.quality[0].note = ''
                  }
                }
              }
              

              if (response.data[0].customerCategory){
                  this.filterOptions(response.data[0].customerCategory)
                  this.filterOptionsSystem(response.data[0].systemCategory)

                  if (!response.data[0].customerID){
                      this.checkCustomerID = false
                      //this.showMainCustomer = true
                  } else {
                      this.checkCustomerID = true
                      //this.showMainCustomer = false                    
                  }
                  if (!response.data[0].systems || response.data[0].systems.length == 0){
                      this.checkSystem = false
                      //this.showMainSystems = true
                  } else {
                      this.checkSystem = true
                      //this.showMainSystems = false                    
                  }
                  if (!response.data[0].surveyDetails){
                    this.data.surveyDetails = {
                      date:moment.unix(),
                      dateStr:moment().format('YYYY-MM-DD'), 
                      status: '', 
                      response:'',
                      note:'',
                      createdBy:{},                       
                    }
                  }
                  if (!response.data[0].accountingDetails){
                    this.data.accountingDetails = {
                      date:moment.unix(),
                      dateStr:moment().format('YYYY-MM-DD'), 
                      status: '', 
                      response:'',
                      note:'',
                      createdBy:{},                       
                    }
                  }
                  if (!response.data[0].documentsRequired){
                    this.data.documentsRequired = []
                  }                  
              }
            this.getCodFAM()
            this.getTasksList()
          }        
          
      })
    },
    
    getTasksList() {
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}tasks/by-project/list/${this.$route.params.id}`, {headers: { authorization:this.tk}}).then((response) => {
        if (response.data) {
          this.tasksList = response.data
        }        
      })
    },
    init(){
      this.axiosInterceptor()
      this.worksite.projectID = this.data._id
      axios.post(`${this.UrlServer}worksites/register`, this.worksite, {headers: { authorization:this.tk}}).then((response) => {
        if(response.data) {
          this.getItem()
          Swal.fire( this.$t("t-msg-worksite-localization-created-title") , this.$t("t-msg-worksite-localization-created-content"), "success");
        }
      })
    },
    getDocs() {
      let tipologia = 'persona_fisica' 
      if (this.data.customerCategory != 'privato') {
        tipologia = 'persona_giuridica'
      }
      this.axiosInterceptor()
        axios.get(`${this.UrlServer}settings/all/documents/list`, 
        { params:{
            type: "tag_document",
            project_type: this.data.systemSubCategory,
            ref: tipologia,
          },
          headers: { authorization:this.tk}
        }).then((response) => {

          if (response.data) {
            this.data.documentsRequired = response.data
            this.updateItem()
          }        
      })
    },
    updateItem(){
       // this.submitted = true;
        this.v$.$touch();
        this.data.createdBy = this.datastore.auth.currentUser
        this.axiosInterceptor()
        axios.put(`${this.UrlServer}projects/${this.$route.params.id}`, this.data, {headers: { authorization:this.tk}} )
          .then(()=>{
            
          })
      },
    upgradeStatus(){
      Swal.fire(
          {
            title: 'Converti in Progetto',
            text: 'Stai per convertire il preventivo in un "Progetto" definitivo, che consentirebbe la lavorazione completa della Commessa.',
            icon: this.$t("t-delete-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
            confirmButtonText: "Procedi, pure!"
          }
          ).then((result) => {
            if (result.value) {
              this.v$.$touch();
              this.data.createdBy = this.datastore.auth.currentUser
              this.data.status='init'
              this.axiosInterceptor()
              axios.put(`${this.UrlServer}projects/${this.$route.params.id}`, this.data, {headers: { authorization:this.tk}} )
                .then(()=>{
                  alert(this.data.status)
                })
            }
          });

    },
    updateDoc(tag){
      let val = document.getElementById(tag).value
      console.log(val)

    },
    updateW(){
      this.axiosInterceptor()
      axios.put(`${this.UrlServer}worksites/${this.data.worksite[0]._id}`, this.data.worksite[0], {headers: { authorization:this.tk}})
      .then(() => {
      })
    },
    getCodFAM(){
      if (this.data.systemSubCategory) {
        axios.get(`${this.UrlServer}categories/filter/product/desc/IMPIANTI-${this.data.systemSubCategory}`,  
        {headers: { authorization:this.tk }} 
        ).then((response)=>{
            if (response.data){
              this.famList = response.data
          } 
        })   
      }
    }, 

  },
  components: {
    //CountTo,
    userSearchModel,
    formAnagraficaCliente,
    Layout,
    PageHeader,
    gcloud,
    timelineTasksWidget,
    gestioneProdottiPreventivo,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
        <!-- Informazioni Generali di registrazione -->
        <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">
              <template v-if="data">
               <template v-if="data.customerID && data.customerID.length==24">
                  <span class="mdi mdi-24px mdi-check pr-5 "></span>
               </template>
               <template v-else>
                  <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                </template>
              </template>                
              Dati Generali - 
              <template v-if="data.customerID">
                <template v-if="data.customerID && data.customerID.length==24">
                 COMMITTENTE: 
                  <span class="text-primary text-bold" v-if="data.customerDetails.ragSoc">{{ data.customerDetails.ragSoc }}</span>&nbsp;
                  <span class="text-secondary text-bold" v-if="data.customerDetails.nome">{{ data.customerDetails.nome }}</span>&nbsp;
                  <span class="text-info text-bold" v-if="data.customerDetails.cognome">{{ data.customerDetails.cognome }}</span>
                </template>
                <template  v-else>
                  <span class="text-danger text-bold" > associare anagrafica cliente</span>
                </template>
              </template>
              <template v-else>
                <span class="text-danger text-bold" > associare anagrafica cliente</span>
              </template>
            </h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
              
              <template v-if="data.status=='quotation'">
                 <span class="orange-color">Preventivo</span>&nbsp;
                 <template v-if="data.quotation.length>0">
                  <strong>{{ data.quotation[0].code }}/{{ data.quotation[0].year }}/{{ data.quotation[0].DBGruppo }}</strong>&nbsp;
                 </template>
                <b-button variant="outline-dark" class="waves-effect waves-light"  title="Trasforma il preventivo in un progetto" @click="upgradeStatus">
                  <i class='mdi mdi-android-studio'></i> Trasforma in progetto
                </b-button>&nbsp;
              </template>
              
                <b-button variant="outline-dark" class="waves-effect waves-light"  title="Aggiorna dati" @click="getItem">
                  <i class='ri ri-restart-line'></i>
                </b-button>
                  &nbsp;

                  <template v-if="$route.name!='my-projects-detail'">
                <template v-if="data.accountingDetails">
                  <div
                    class="form-check form-switch form-switch-right form-switch-md"
                    v-if="data.accountingDetails.contract"
                  >
                  <button type="button" class="btn btn-secondary" title="Apri scheda di lavorazione" @click="$router.push('/projects/detail/plan/'+$route.params.id)"> <i class="mdi mdi-android-studio"></i></button>
                  &nbsp;

                  </div>
                </template>
              </template>
              
                  <label for="FormSelectDefault" class="form-label text-muted"
                  >Mostra</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="FormSelectDefault"
                  v-model="showMainQP1"

                />
              </div>
            </div>
          </div>
          <!-- end card header -->
          <div class="card-body" v-if="showMainQP1">
          <form id="genDataFormQP" class="needs-validation"  @submit.prevent="updateItem">
            <div class="row g-3 background-blue-light m-2">
                <div class="col-lg-12 text-center">
                  <h3>Piano di Qualità</h3>
                </div>
              </div>
              <div class="row g-3 border-blue-light-top-left-right pl-5 pr-5 m-2">
                <div class="col-lg-6 col-md-12">
                    <div class="input-group mb-3" v-if="data.quality">
                      <div class="input-group-prepend pr-5 "  v-if="data.quality[0].titolo">
                        <template v-if="data.quality[0].titolo!=''">
                          <span class="mdi mdi-24px mdi-check pr-5 "></span>
                        </template>
                        <template v-else>
                          <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                        </template>
                        <span class="">Titolo</span> 
                      </div>
                      <div class="input-group-prepend pr-5 "  v-else>
                        <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                        <span class="">Titolo</span> 
                      </div>
                      <input type="text" class="form-control" id="qtitolo" v-model="data.quality[0].titolo"  @blur="updateItem">                                	


                    </div>        
                  </div>   
                  <div class="col-lg-3 col-md-12">
                    <div class="input-group mb-3"  v-if="data.quality">
                      <div class="input-group-prepend"  v-if="data.quality[0].statoLavori">
                        <template v-if="data.quality[0].statoLavori!=''">
                          <span class="mdi mdi-24px mdi-check pr-5 "></span>
                        </template>
                        <template v-else>
                          <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                        </template>
                         <span class="pr-5">Stato Lavori</span> 
                      </div>
                      <div class="input-group-prepend"  v-else>
                          <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                         <span class="pr-5">Stato Lavori</span> 
                      </div>
                      <select class="form-select"  placeholder="Seleziona  Stato Lavori" data-trigger id="statoLavori" v-model="data.quality[0].statoLavori"  @change="updateItem" >
                        <option value="">Seleziona Stato Lavori</option>
                        <option value="consegnati">Consegnati</option>
                        <option value="consegnati - parziale">Consegnati (parziale)</option>
                        <option value="in corso">In corso</option>
                        <option value="in corso - parziale">In corso (parziale)</option>
                        <option value="sospesi">Sospesi</option>
                        <option value="ultimati">Ultimati</option>
                        <option value="collaudati">Collaudati</option>
                        <option value="chiusi">Chiusi</option>
                        <option value="archiviati">Archiviati</option>

                      </select>    
                  </div>

                </div>
              </div>
              <div class="row g-3 border-blue-light-left-right pl-5 pr-5 m-2">
                <div class="col-lg-3 col-md-12">
                    <div class="input-group mb-3" v-if="data.quality">
                      <div class="input-group-prepend pr-5 "  v-if="data.quality[0].importoLavori">
                        <template v-if="data.quality[0].importoLavori>0">
                          <span class="mdi mdi-24px mdi-check pr-5 "></span>
                        </template>
                        <template v-else>
                          <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                        </template>
                        <span class="">Importo Lavori</span> 
                      </div>
                      <div class="input-group-prepend pr-5 "  v-else>
                        <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                        <span class="">Importo Lavori</span> 
                      </div>
                      <input type="number" class="form-control" id="qimportoLavori" v-model="data.quality[0].importoLavori"  @blur="updateItem">                                	
                    </div>        
                </div>   
                <div class="col-lg-3 col-md-12">
                    <div class="input-group mb-3" v-if="data.quality">
                      <div class="input-group-prepend pr-5 "  v-if="data.quality[0].importoOneriSicurezza">
                        <template v-if="data.quality[0].importoOneriSicurezza>0">
                          <span class="mdi mdi-24px mdi-check pr-5 "></span>
                        </template>
                        <template v-else>
                          <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                        </template>
                        <span class="">Importo Oneri di Sicurezza</span> 
                      </div>
                      <div class="input-group-prepend pr-5 "  v-else>
                        <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                        <span class="">Importo Oneri di Sicurezza</span> 
                      </div>
                      <input type="number" class="form-control" id="qimportoLavoriOneriSicurezza" v-model="data.quality[0].importoOneriSicurezza"  @blur="updateItem">                                	
                    </div>  
                </div>
              </div>
              <div class="row g-3 border-blue-light-left-right pl-5 pr-5 m-2">
                <div class="col-lg-6 col-md-12">
                    <div class="input-group mb-3" v-if="data.quality">
                      
                      <div class="input-group-prepend pr-5 ">
                        <template v-if="data.quality[0].note">
                          <span class="mdi mdi-24px mdi-check pr-5 "></span>
                        </template>
                        <template v-else>
                          <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                        </template>
                        <span class="">Oggetto</span> 
                      </div>
                      <textarea class="form-control" maxlength="250" rows="5" placeholder="Descrizione " v-model="data.quality[0].note"></textarea>
                    
                    </div>    
                      
                  </div>   

                  <div class="col-lg-3 col-md-12">                      
                  </div>
              </div>
              <div class="row g-3 border-blue-light-bottom-left-right pl-5 pr-5 m-2">
                <div class="col-lg-3 col-md-12">
                    <div class="input-group mb-3" v-if="data.quality">
                      <div class="input-group-prepend pr-5 "  v-if="data.quality[0].codiceCIG">
                        <template v-if="data.quality[0].codiceCIG!=''">
                          <span class="mdi mdi-24px mdi-check pr-5 "></span>
                        </template>
                        <template v-else>
                          <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                        </template>
                        <span class="">Codice CIG</span> 
                      </div>
                      <div class="input-group-prepend pr-5 "  v-else>
                        <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                        <span class="">Codice CIG</span> 
                      </div>
                      <input type="text" class="form-control" id="qcodiceCIG" v-model="data.quality[0].codiceCIG"  @blur="updateItem">                                	
                    </div>        
                </div>   
                <div class="col-lg-3 col-md-12">
                    <div class="input-group mb-3" v-if="data.quality">
                      <div class="input-group-prepend pr-5 "  v-if="data.quality[0].codiceCUP">
                        <template v-if="data.quality[0].codiceCUP!=''">
                          <span class="mdi mdi-24px mdi-check pr-5 "></span>
                        </template>
                        <template v-else>
                          <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                        </template>
                        <span class="">Codice CUP</span> 
                      </div>
                      <div class="input-group-prepend pr-5 "  v-else>
                        <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                        <span class="">Codice CUP</span> 
                      </div>
                      <input type="text" class="form-control" id="qcodiceCUP" v-model="data.quality[0].codiceCUP"  @blur="updateItem">                                	
                    </div>  
                </div>
              </div>
          </form>

        <!-- Card -->
        <div class="row g-3  m-2">
      <div class="col-lg-2 col-md-12">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between" >
              <div>
                <p class="fw-medium text-muted mb-0"></p>
                <h4 class="mt-4 ff-secondary fw-semibold" >
                  <a href="javascript:void(0)"  @click="$router.push('/quality-control/detail/journal-list-view/'+ $route.params.idpq)"> Calendario Lav.</a>
                   
                </h4>
                <!--
                <h2 class="mt-4 ff-secondary fw-semibold" v-if="dataSumm.total">
                  <count-to :startVal="0" :endVal="dataSumm.total" :duration="5000"></count-to>
                </h2>
                <h2 class="mt-4 ff-secondary fw-semibold" v-else>
                  0
                </h2>
                -->
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-info-2 rounded-circle fs-4">
                    <i class="ri ri-calendar-line"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-12">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <h4 class="mt-4 ff-secondary fw-semibold" >
                  Attori
                </h4>
                <!--
                <p class="fw-medium text-muted mb-0">Commesse in lavorazione</p>
                <h2 class="mt-4 ff-secondary fw-semibold" v-if="dataSumm.processing">
                  <count-to :startVal="0" :endVal="dataSumm.processing" :duration="5000"></count-to>
                </h2>
                <h2 class="mt-4 ff-secondary fw-semibold" v-else>
                  0
                </h2>
              -->
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-info rounded-circle fs-4">
                    <i class="mdi mdi-account-group"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-12">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div><!--
                <p class="fw-medium text-muted mb-0">Commesse in lavorazione</p>
                <h2 class="mt-4 ff-secondary fw-semibold" v-if="dataSumm.processing">
                  <count-to :startVal="0" :endVal="dataSumm.processing" :duration="5000"></count-to>
                </h2>
                <h2 class="mt-4 ff-secondary fw-semibold" v-else>
                  0
                </h2>
              -->
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-info rounded-circle fs-4">
                    <i class="mdi mdi-timer-sand"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-12">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div><!--
                <p class="fw-medium text-muted mb-0">Commesse in attesa</p>
                <h2 class="mt-4 ff-secondary fw-semibold" v-if="dataSumm.pending">
                  <count-to :startVal="0" :endVal="dataSumm.pending" :duration="5000"></count-to>
                </h2>
                <h2 class="mt-4 ff-secondary fw-semibold" v-else>
                  0
                </h2>
              -->
              </div>
              
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-warning rounded-circle fs-4">
                    <i class="bx bx-time-five "></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-12">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div><!--
                <p class="fw-medium text-muted mb-0">Commesse con problemi</p>
                <h2 class="mt-4 ff-secondary fw-semibold" v-if="dataSumm.pending">
                  <count-to :startVal="0" :endVal="dataSumm.problem" :duration="5000"></count-to>
                </h2>
                <h2 class="mt-4 ff-secondary fw-semibold" v-else>
                  0
                </h2>
              -->
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-danger rounded-circle fs-4">
                    <i class="bx bx-alarm-exclamation"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-12">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div><!--
                <p class="fw-medium text-muted mb-0">Commesse chiuse</p>
                <h2 class="mt-4 ff-secondary fw-semibold" v-if="dataSumm.completed">
                  <count-to :startVal="0" :endVal="dataSumm.completed" :duration="5000"></count-to>
                </h2>
                <h2 class="mt-4 ff-secondary fw-semibold" v-else>
                  0
                </h2>
              -->
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-success rounded-circle fs-4">
                    <i class="ri-checkbox-circle-line"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
        <!-- / Card -->

          </div>
        </div>

      </div>
    <!-- end col -->
    </div>
    <!-- /Piano di qualità - Generale  -->  
    <!-- Informazioni Generali di registrazione -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">
              <template v-if="data">
               <template v-if="data.customerID && data.customerID.length==24">
                  <span class="mdi mdi-24px mdi-check pr-5 "></span>
               </template>
               <template v-else>
                  <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                </template>
              </template>                
              Informazioni di registrazione e Localizzazione
            </h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
              
                  <template v-if="$route.name!='my-projects-detail'">
                <template v-if="data.accountingDetails">
                  <div
                    class="form-check form-switch form-switch-right form-switch-md"
                    v-if="data.accountingDetails.contract"
                  >
                  <button type="button" class="btn btn-secondary" title="Apri scheda di lavorazione" @click="$router.push('/projects/detail/plan/'+$route.params.id)"> <i class="mdi mdi-android-studio"></i></button>
                  &nbsp;

                  </div>
                </template>
              </template>
                  <label for="FormSelectDefault" class="form-label text-muted"
                  >Mostra</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="FormSelectDefault"
                  v-model="showMainProject"

                />
              </div>
            </div>
          </div>
          <!-- end card header -->
          <div class="card-body" v-if="showMainProject">

          <form id="genDataForm" class="needs-validation"  @submit.prevent="updateItem">
              <div class="row g-3 background-green-light m-2">
                <div class="col-lg-12 text-center">
                  <h3>Caratteristiche specifiche</h3>
                </div>
              </div>
              <div class="row g-3 border-green-light pl-5 pr-5 m-2">
                <div class="col-lg-2">
                  <label for="name" class="form-label">{{ $t("t-project-name") }}</label>
                  <input type="text" class="form-control" id="name" v-model="data.name"  @blur="updateItem">                                	
                </div>
                <div class="col-md-12 col-lg-2">
                    <label for="source-field" class="form-label">{{ $t("t-order-origin") }} <span class="text-danger">*</span></label>
                        <select class="form-select" :class="{
                      'is-invalid': submitted && v$.data.source.$error,
                    }" placeholder="Seleziona origine commessa" data-trigger id="sourcefield" v-model="data.source" @change="updateItem">
                          <option value="">Origine commessa</option>
                          <option value="bando">Bando pubblico</option>
                          <option value="diretto">Contatto diretto</option>
                          <option value="rete_commerciale">Rete commerciale</option>
                        </select>
                        <div v-for="(row, index) in v$.data.source.$errors" :key="index" class="invalid-feedback">
                          <span v-if="row.$message">{{ row.$message }}</span>
                        </div>
                </div>
                <div class="col-md-12 col-lg-2 ">
                      <label for="customerCategory-field" class="form-label">{{ $t("t-customer-category") }} <span class="text-danger">*</span></label>
                        <select class="form-select" :class="{
                      'is-invalid': submitted && v$.data.customerCategory.$error,
                    }" placeholder="Seleziona origine commessa" data-trigger id="customerCategory-field" v-model="data.customerCategory" @change="filterOptions(data.customerCategory)">
                          <option value="">Seleziona tipologia cliente</option>
                          <option value="azienda">Azienda</option>
                          <option value="privato">Privato</option>
                          <option value="pubblico">Pubblico</option>
                        </select>
                        <div v-for="(row, index) in v$.data.customerCategory.$errors" :key="index" class="invalid-feedback">
                          <span v-if="row.$message">{{ row.$message }}</span>
                        </div>                
                </div>
                <div class="col-md-12 col-lg-2">
                    <label for="fiscalBenefit-field" class="form-label">{{ $t("t-fiscal-benefit") }} <span class="text-danger">*</span></label>
                 
                        <select class="form-select" :class="{
                      'is-invalid': submitted && v$.data.fiscalBenefit.$error,
                    }" placeholder="Seleziona origine commessa" data-trigger id="fiscalBenefit-field" v-model="data.fiscalBenefit" @change="updateItem">
                          <option v-for="item in optionsFiscalBenefit" :value="item.value" :key="item">{{item.label}}</option>
                         
                        </select>
                        <div v-for="(row, index) in v$.data.fiscalBenefit.$errors" :key="index" class="invalid-feedback">
                          <span v-if="row.$message">{{ row.$message }}</span>
                        </div>
                </div>
                <div class="col-md-12 col-lg-2 ">
                     <label for="fiscalBenefit-field" class="form-label">Consulente di riferimento:</label>
                     <div class="pl-5">
                      <p >
                        <strong v-if="data.consultantID">{{data.consultantDetails.firstName }} {{data.consultantDetails.lastName }}</strong> <userSearchModel :ID="data._id" type="project" @getParent="getParent()"></userSearchModel>
                      </p>
                    </div>
                </div>
                <div class="col-md-12 col-lg-2">
                     <label for="fiscalBenefit-field" class="form-label">Avvio pratica</label>
                     <div class="pl-5">
                        in data: <b>{{ convertTmpToDate(data.dateTmp)}}</b>
                        <p>
                          da:  <b v-if="data.createdBy">{{data.createdBy.firstName}} {{data.createdBy.lastName}}</b>
                        </p>
                     </div>
                </div>
              </div>
              <div class="row g-3 border-green-light pl-5 pr-5 m-2">
                <div class="col-lg-3 col-md-12">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend pr-5 ">
                        <template v-if="data.systemCategory && data.systemCategory!=''">
                          <span class="mdi mdi-24px mdi-check pr-5 "></span>
                        </template>
                        <template v-else>
                          <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                        </template>
                        <span class="">Tipologia impianto</span> 
                      </div>
                      <select 
                            class="form-select" 
                            :class="{
                            'is-invalid': submitted && v$.data.systemCategory.$error,}"
                             placeholder="Tipologia impianto" 
                             data-trigger id="systemCategory-field" v-model="data.systemCategory" 
                             @change="filterOptionsSystem(data.systemCategory)"
                             >
                                <option value="">Seleziona tipologia impianto</option>
                                <option value="acqua_calda_sanitaria">Acqua calda sanitaria</option>
                                <option value="climatizzazione">Climatizzazione</option>
                                <option value="edilizia">Edilizia</option>
                                <option value="energia_elettrica">Energia elettrica</option>
                          </select>
                          <div v-for="(row, index) in v$.data.systemCategory.$errors" :key="index" class="invalid-feedback">
                            <span v-if="row.$message">{{ row.$message }}</span>
                          </div>
                    </div>        
                  </div>   
                  <div class="col-lg-3  col-md-12">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <template v-if="data.systemSubCategory && data.systemSubCategory!=''">
                          <span class="mdi mdi-24px mdi-check pr-5 "></span>
                        </template>
                        <template v-else>
                          <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                        </template>
                         <span class="pr-5">Categoria</span> 
                      </div>
                      
                      <select class="form-select" :class="{
                          'is-invalid': submitted && v$.data.systemSubCategory.$error,
                        }" placeholder="Tipologia impianto" data-trigger id="systemCategory-field" v-model="data.systemSubCategory" @change="getDocs()" >
                          <option v-for="item in optionsSystems" :value="item.value" :key="item">{{item.label}}</option>
                      </select>
                      <div v-for="(row, index) in v$.data.systemSubCategory.$errors" :key="index" class="invalid-feedback">
                        <span v-if="row.$message">{{ row.$message }}</span>
                      </div>
                    </div>        
                  </div> 
                  <div class="col-lg-3 col-md-12">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <template v-if="data.tipoImpiantoFatturato && data.tipoImpiantoFatturato!=''">
                          <span class="mdi mdi-24px mdi-check pr-5 "></span>
                        </template>
                        <template v-else>
                          <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                        </template>
                         <span class="pr-5">Dettaglio impianto</span> 
                      </div>
                        
                      <select class="form-select"  placeholder="Seleziona impianto da fatturare" data-trigger id="billInput" v-model="data.tipoImpiantoFatturato"  @change="updateItem" >
                        <option value="">Seleziona impianto da fatturare</option>
                        <option  v-for="item in famList" :key="item._id" :value="item.CodArt">{{ item.Nome }}</option>
                      </select>    
                  </div>

                </div>


              </div>
          </form>
          <div class="row g-3 pl-2  m-2">
            <div class="col-lg-12 col-md-12 background-grey-ce ml-5 pl-5 pt-2">
              <template v-if="data">
              <template v-if="data.worksite == false || data.worksite == null">
                <div class="mb-3">
                  <b-alert show variant="primary">
                  <strong>Attenzione:</strong> non è stata ancora indicato il luogo di installazione
                  </b-alert>
                  <button type="button" class="btn btn-primary" @click="init"><i class="bx bx-settings"></i>Inserisci luogo di installazione >></button>
                  </div>
              </template>
              <template v-else>
                <div class="col-lg-12" v-if="data.worksite.length>0">
                  <gcloud :data="data.worksite[0]" type="detail-project" ></gcloud>
                </div>
              </template>
              </template>
            </div>
          </div>

          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- /Informazioni Generali di registrazione  -->    
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">
              <template v-if="data">
               <template v-if="data.customerID && data.customerID.length==24">
                  <span class="mdi mdi-24px mdi-check pr-5 "></span>
               </template>
               <template v-else>
                  <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                </template>
              </template>  
                Anagrafica cliente
                <template v-if="data.customerDetails">
                  <template v-if="!data.customerDetails.iban || data.customerDetails.iban==''">
                    <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                    <b-badge variant="danger" class="badge-label" >
                      <i class="mdi mdi-alert-medium"></i> 
                      &nbsp;<span class="pr-2">Attenzione: IBAN non presente!</span>
                    </b-badge>
                  </template>
                </template>
            </h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
                <b-button variant="outline-dark" class="waves-effect waves-light"  title="Aggiorna dati" @click="getItem">
                  <i class='ri ri-restart-line'></i>
                </b-button>
                &nbsp;
                <label for="FormSelectDefault" class="form-label text-muted"
                  >Mostra</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="FormSelectDefault"
                  v-model="showMainCustomer"

                />
              </div>
            </div>
          </div>
          <div class="card-body" v-if="showMainCustomer">
            <!-- Form anagrafica -->
             <formAnagraficaCliente :projectID="data._id" :data="data" v-on:getParent="getParent()"/>
            <!-- / Form anagrafica -->
          </div>
        </div>
      </div>
    </div>

   
    <div class="row"  v-if="data.status!=='quotation'">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
           
            <div class="card-title mb-0 flex-grow-1">

                <div class="row">
                  <div class="col-lg-12 col-md-6">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <template v-if="data.systemCategory && data.systemCategory!=''">
                            <template v-if="checkDocuments(data.documentsRequired)">
                              <template v-if="r==0">
                                <span class="mdi mdi-24px mdi-check pr-5 "></span>
                              </template>
                              <template v-else>
                                <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                              </template>
                            </template>
                            <template v-else>
                              <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                            </template>
                        </template>
                        <template v-else>
                          <span class="mdi mdi-24px mdi-alert pr-5 "> Indicare tipologia di impianto</span>
                        </template>
                        <span class="pr-5">Controllo documentazione - Raccolta dati</span>
                        <b-badge variant="danger" class="badge-label" ><i class="mdi mdi-alert-medium"></i> 
                            <strong>Attenzione!</strong> 
                              &nbsp;<span class="pr-2"><template v-if="r==1"> manca ancora </template><template v-else>mancano ancora</template></span>
                            <b>{{r}}</b> <template v-if="r==1"> documento</template><template v-else>documenti</template> da allegare 
                        </b-badge>
                        
                      </div>
                    </div>        
                  </div>   
                  <div class="col-lg-12 col-md-6">
                  </div>   
                </div>
            </div>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
              <b-button variant="outline-dark" class="waves-effect waves-light"  title="Aggiorna dati" @click="getItem">
                  <i class='ri ri-restart-line'></i>
                </b-button>               
              &nbsp;
              <template  v-if="$route.name!='my-projects-detail'">
                <button type="button" class="btn btn-secondary" title="Apri scheda di lavorazione"  @click="$router.push('/projects/detail/dms/'+$route.params.id)"> <i class="bx bx-edit-alt"></i></button>
              </template>
              <template v-else>
                <button type="button" class="btn btn-secondary" title="Apri scheda di lavorazione"  @click="$router.push('/my-projects/detail/dms/'+$route.params.id)"> <i class="bx bx-edit-alt"></i></button>
              </template>
              &nbsp;
                <label for="showMainDocuments" class="form-label text-muted"
                  >Mostra</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="showMainDocuments"
                  v-model="showMainDocuments"
                />
              </div>
            </div>
          </div>
          <div class="card-body" v-if="showMainDocuments">
            <div class="row mb-50">
                <div class="col-lg-12 col-md-12">                                                    
                  <div>   
                    <p class="text-muted bold">Documentazione richiesta</p>
                      <p class="text-muted">Il controllo della documentazione prevista dalla pratica viene effettuata anche automaticamente sulla base della documentazione caricata in cartella</p>
                        
                        <div class="form-check  form-check-success mb-3 form-check-inline" v-for="item in data.documentsRequired" :key="item._id">
                          <input class="form-check-input" type="checkbox" :id="item.tag" v-model="item.exist" :ref="item.tag" :value="item.exist" onclick="return false;">
                            <label class="form-check-label" :for="item.tag">
                              {{item.label}}
                            </label>
                        </div>                                                        
                  </div>
                </div>
            </div>
            <div class="mt-50">

            </div>
          
          </div>
        </div>
      </div>
    </div> 
<template v-if="data.status!=='quotation'">
    <div class="row" v-if="data.surveyDetails">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
           
            <div class="card-title mb-0 flex-grow-1">

                <div class="row">
                  <div class="col-lg-3">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <template v-if="data.surveyDetails.status=='fatto' && data.surveyDetails.response!='negativo'">
                          <span class="mdi mdi-24px mdi-check pr-5 "></span>
                        </template>
                        <template v-else>
                          <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                        </template>
                        <span class="pr-5">Sopralluogo
                        <template v-if="data.surveyDetails.response!=''">
                          con esito
                          <template v-if="data.surveyDetails.response=='positivo'">
                          <span class="mdi mdi-24px mdi-check pr-5 positive"> {{data.surveyDetails.response}}</span> 
                          </template>
                          <template v-else>
                            <span class="mdi mdi-24px mdi-alert pr-5 negative"> {{data.surveyDetails.response}}</span> 
                          </template>
                         
                        </template>  
                        </span> 
                      </div>

                    </div>        
                  </div>   
                  <div class="col-lg-3">
                    <div class="input-group mb-3">

                    </div>        
                  </div>   
                </div>
            </div>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
                v-if="$route.name!='my-projects-detail'"
                >

              <button type="button" class="btn btn-secondary" title="Apri scheda di lavorazione" @click="$router.push('/projects/detail/inspection/'+$route.params.id)"> <i class="bx bx-edit-alt"></i></button>
              &nbsp;
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>       
    <div class="row" v-if="data.accountingDetails">
      <div class="col-lg-12" v-if="data.accountingDetails">
        <div class="card">
          <div class="card-header align-items-center d-flex">
           
            <div class="card-title mb-0 flex-grow-1">

                <div class="row">
                  <div class="col-lg-12">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <template v-if="data.surveyDetails.response!=''">
                          <template v-if="data.accountingDetails">
                            <template v-if="data.accountingDetails.contract">
                              <span class="mdi mdi-24px mdi-check pr-5 "></span>
                              <span class="pr-5">Registrazione amministrativa</span> 
                            </template>
                            <template v-else>
                              <span class="mdi mdi-24px mdi-pause pr-5 "></span>
                              <span class="pr-5">Registrazione amministrativa</span> 
                              <b-badge variant="warning" class="badge-label" ><i class="mdi mdi-alert-medium"></i> 
                            
                                &nbsp;<span class="pr-2">In attesa conferma stipula contratto.</span>
                              </b-badge>
                            </template>
                          </template>
                        </template>
                        <template v-else>
                          <template v-if="data.surveyDetails.response=='negativo'">
                           <span class="mdi mdi-24px mdi-alert pr-5 "></span>
                           <span class="pr-5">Registrazione amministrativa</span>
                            <b-badge variant="danger" class="badge-label" ><i class="mdi mdi-alert-medium"></i> 
                            <strong>Attenzione!</strong> 
                              &nbsp;<span class="pr-2">Non è possibile procedere con la registrazione amministrativa poiché il sopralluogo da dato esito negativo.</span>
                            </b-badge>
                          </template>
                          <template v-else>
                           <span class="mdi mdi-24px mdi-pause pr-5 "></span>
                           <span class="pr-5">Registrazione amministrativa</span>
                            <b-badge variant="warning" class="badge-label" ><i class="mdi mdi-alert-medium"></i> 
                            <strong>Attenzione!</strong> 
                              &nbsp;<span class="pr-2">Non è possibile procedere con la registrazione amministrativa in assenza di un sopralluogo con esito positivo.</span>
                            </b-badge>
                          </template>

                        </template>

                       

                      </div>

                    </div>        
                  </div>   
 
                </div>
            </div>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
                v-if="$route.name!='my-projects-detail'"
              >
              <button type="button" class="btn btn-secondary" title="Apri scheda di lavorazione"  @click="$router.push('/projects/detail/accountancy/'+$route.params.id)"> <i class="bx bx-edit-alt"></i></button>
              &nbsp;
                 <template v-if="data.surveyDetails.response=='positivo'">
                <!--- <label for="FormSelectAdmin" class="form-label text-muted"
                      >Mostra</label
                    >
                    <input
                      class="form-check-input code-switcher"
                      type="checkbox"
                      id="FormSelectAdmin"
                      v-model="showMainAdmin"
                    />-->
                 </template>
              </div>
            </div>
          </div>
         <!--<template  v-if="data.accountingDetails">
            <div class="card-body" v-if="showMainAdmin">
              <accountingManager :ID="$route.params.id" :data="data.accountingDetails" v-on:getParent="getParent()" />
            </div>
          </template>-->
        </div>
      </div>
    </div>   
    <div class="row" v-if="data.accountingDetails">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <div class="card-title mb-0 flex-grow-1">
                <div class="row">

                  <div class="col-lg-3">
                    <div class="input-group mb-3">

                      <div class="input-group-prepend">
                        <template v-if="data">
                          <template v-if="data.accountingDetails">
                            <template v-if="data.accountingDetails">
                            <template v-if="data.accountingDetails.contract">
                              <span class="mdi mdi-24px mdi-check pr-5 "></span>
                              <span class="pr-5">Lavorazione pratica e progettazione</span> 
                            </template>
                            <template v-else>
                              <span class="mdi mdi-24px mdi-pause pr-5 "></span>
                              <span class="pr-5">Lavorazione e progettazione</span> 
                              <b-badge variant="warning" class="badge-label" ><i class="mdi mdi-alert-medium"></i> 
                                &nbsp;<span class="pr-2">In attesa conferma stipula contratto.</span>
                              </b-badge>
                            </template>
                          </template>
                          </template>
                        </template>
                      </div>
                    </div>        
                  </div>   
                  <div class="col-lg-3">
                    <div class="input-group mb-3">

                    </div>        
                  </div>   
                </div>
            </div>
            <div class="flex-shrink-0">
              <template v-if="$route.name!='my-projects-detail'">
                <template v-if="data.accountingDetails">
                  <div
                    class="form-check form-switch form-switch-right form-switch-md"
                    v-if="data.accountingDetails.contract"
                  >
                  <button type="button" class="btn btn-secondary" title="Apri scheda di lavorazione" @click="$router.push('/projects/detail/plan/'+$route.params.id)"> <i class="bx bx-edit-alt"></i></button>
                  &nbsp;

                  </div>
                </template>
              </template>
            </div>
          </div>

        </div>
      </div>

    </div>   
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <div class="card-title mb-0 flex-grow-1">
                <div class="row">

                  <div class="col-lg-3">
                    <div class="input-group mb-3">

                      <div class="input-group-prepend">
                        <template v-if="tasksList.length>0">
                          <span class="mdi mdi-24px mdi-check pr-5 "></span>
                          <span class="pr-5">Timeline Lavori - Eventi</span> 
                        </template>
                        <template v-else>
                          <span class="mdi mdi-24px mdi-pause pr-5 "></span>
                          <span class="pr-5">Timeline Lavori - Eventi</span> 
                          <b-badge variant="warning" class="badge-label" >
                           
                            &nbsp;<span class="pr-2">Nessuna lavorazione avviata</span>
                          </b-badge>
                        </template>
                      </div>

                    </div>        
                  </div>   
                  <div class="col-lg-3">
                    <div class="input-group mb-3">

                    </div>        
                  </div>   
                </div>
            </div>
            <div class="flex-shrink-0">
              
             
                <template v-if="tasksList">
                <div
                  class="form-check form-switch form-switch-right form-switch-md"
                  v-if="tasksList.length>0"
                >
                <template></template>
                  <b-button variant="outline-dark" class="waves-effect waves-light"  title="Aggiorna dati" @click="getItem">
                    <i class='ri ri-restart-line'></i>
                  </b-button>
                  &nbsp;
                  <template v-if="$route.name!='my-projects-detail'">
                    <button type="button" class="btn btn-secondary" title="Apri scheda di lavorazione" @click="$router.push('/worksite/detail/'+$route.params.id)"> <i class="bx bx-edit-alt"></i></button>
                  </template>
                  &nbsp;
                    <label for="FormSelectSystem" class="form-label text-muted"
                      >Mostra</label
                    >
                    <input
                      class="form-check-input code-switcher"
                      type="checkbox"
                      id="FormSelectSystem"
                      v-model="showTechProgect"
                    />
                  </div>
                </template>
            </div>
          </div>
          <div class="card-body background-grey-ce" v-if="showTechProgect">
            <template v-if="data">
              <template v-if="data.worksite.length>0">
                <div class="mb-50 mt-20">
                  <h4>Dati cantiere</h4>
                  <div class="row card-body background-white ml-2 mr-2">
                      <div class="col">
                        Inizio lavori: <br><h5>  <strong>{{ convertTmpToDate(data.worksite[0].start_tmp) }}</strong></h5>
                      </div>
                      <div class="col">
                        Fine lavori: <br> <h5>  <strong>{{ convertTmpToDate(data.worksite[0].end_tmp) }}</strong></h5>
                      </div>
                      <div class="col">
                        Stato lavori: 

                          <template v-if="data.worksite[0].status=='init'">
                            <b-alert show dismissible variant="primary" class="alert-solid alert-label-icon fade show" role="alert">
                              <i class="ri-stack-line label-icon"></i><strong>Avviati</strong>
                            </b-alert>
                          </template>
                          <template v-else-if="data.worksite[0].status=='in progress'">
                            <b-alert show dismissible variant="info" class="alert-solid alert-label-icon fade show" role="alert">
                              <i class="ri-send-plane-2-line label-icon"></i><strong>In corso</strong>
                            </b-alert>
                          </template>
                          <template v-else-if="data.worksite[0].status=='blocked'">
                            <b-alert show dismissible variant="danger" class="alert-solid alert-label-icon fade show" role="alert">
                              <i class="ri-message-line label-icon"></i><strong>Bloccato</strong>
                            </b-alert>
                          </template>
                          <template v-else-if="data.worksite[0].status=='canceled'">
                            <b-alert show dismissible variant="warning" class="alert-solid alert-label-icon fade show" role="alert">
                              <i class="ri-chat-delete-line label-icon"></i><strong>Annullato</strong>
                            </b-alert>
                          </template>
                          <template v-else-if="data.worksite[0].status=='completed'">
                            <b-alert show dismissible variant="secondary" class="alert-solid alert-label-icon fade show" role="alert">
                              <i class="ri-archive-drawer-line label-icon"></i><strong>Completato</strong>
                            </b-alert>
                          </template>
                          <template v-else-if="data.worksite[0].status=='installed'">
                            <b-alert show dismissible variant="success" class="alert-solid alert-label-icon fade show" role="alert">
                              <i class="ri-flag-2-line label-icon"></i><strong>Impianto installato</strong>
                            </b-alert>                            
                          </template>
                          <template v-else>
                            <i class="icon ri-quill-pen-line" variant="primary"></i>
                          </template>

                      </div>
                  </div>
                </div>
              </template>
            </template>
            <template v-if="tasksList.length>0">
              <timelineTasksWidget :data="tasksList" />
            </template>
             
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>  
</template>
<template v-if="data.status=='quotation'">
 
  <template v-if="data.quotation">
    <template v-if="data.quotation.length>0">
      <gestioneProdottiPreventivo type="quotation" :projectID="data._id" :quotationID="data.quotation[0]._id"  :data="data"  />  
    </template>
  </template>

</template>
  </Layout>
</template>
